import React from "react";

import {
  Redirect
} from "react-router-dom";

import MonacoEditor from '../../lib/monaco-editor';
import { db, auth } from "../../lib/Firebase";
import Compiler from "../../lib/Compiler";
import { LANGUAGES } from "../../constants/client"

import Select from 'react-styled-select'

import Firepad from "firepad";

import "./index.scss";

class Editor extends React.Component {
  constructor(props) {
    super(props);

    const codeId = props.match.params.codeId;
    
    this.state = {
      codeId,
      language: "javascript",
      languageId: "4",
      theme: "vs-dark",
      outputs: [],
      title: codeId,
      users: []
    };

    this.outputRef = React.createRef();

    if (codeId) {
      this.firepadRef = db.ref("firepads").child(codeId);
    }
  }

  componentDidMount() {

    if (!this.firepadRef) return;

    this.firepadRef.child("title").once("value", snapshot => {
      this.setState({ title: snapshot.val() || this.state.codeId });
    });
  
    this.firepadRef.child("users").once("value", snapshot => {
      this.setState({ users: Object.entries(snapshot.val() || {}) });
    });
  }

  async editorDidMount(editor, monaco) {
    
    if (!this.firepadRef || !this.state.codeId) return;
    
    editor.getModel().updateOptions({ tabSize: 2 });
    editor.focus();

    const user = auth.currentUser;

    if (!this.firepadInstance) {
      this.firepadInstance = Firepad.fromMonaco(this.firepadRef, editor, {
        userId: user.uid,
        defaultText: "// Type code here"
      });
    }

    editor.dispose = this.firepadInstance.dispose.bind(this.firepadInstance);

    this.setState({
      currentUser: user
    });;
    
    const isOwnerSet = await this.firepadRef.child("owner").once("value");

    if (!isOwnerSet.val()) {
      await this.firepadRef.child("owner").set(user.uid);
    }
    
    await db.ref(`users/${user.uid}/firepads/${this.state.codeId}`).set({
      opened: Date.now()
    });

  }

  renderRedirect() {
    if (!this.state.codeId) {
      return <Redirect to='/' />
    }
  }

  setLanguage(value) {
    const language = LANGUAGES.find(langObj => (langObj.id === value)).langCode;
    this.setState({ language, languageId: value });
  }

  setTheme(theme) {
    this.setState({
      theme
    });

    const root = document.querySelector(".App");
    root.classList.remove("dark");
    root.classList.remove("light");

    root.classList.add(theme.replace("vs-", ""));
  }

  async runCode() {
    const selectedCode = this.getSelection();
    this.setState({
      outputs: this.state.outputs.concat([selectedCode ? "Running Selected Code..." : "Running Code..."])
    }, this.scrollOutput.bind(this));
    
    const code = selectedCode || this.firepadInstance.getText();
    const result = await Compiler.getOutput(code, this.state.languageId);
    
    const resultText = <pre>  >  {result.errors ? result.errors : result.output}</pre>;
    this.setState({
      outputs: this.state.outputs.concat([resultText])
    });
    this.scrollOutput();
  }

  getSelection() {
    const editor = this.firepadInstance.editor_
    return editor.getModel().getValueInRange(editor.getSelection());
  }

  scrollOutput() {
    this.outputRef.current.scrollTop = this.outputRef.current.scrollHeight;
  }

  clearOutput() {
    this.setState({
      outputs: []
    });
  }

  render() {

    const { language, theme, outputs, title, users } = this.state;

    const options = {
      fontSize: 14,
      fontFamily: "Menlo, Monaco, 'Courier New', monospace",
      minimap: {
        enabled: false
      },
      theme,
      language
    };

    const languageOptions = LANGUAGES.map(langObj => {
      return {
        label: langObj.name,
        value: langObj.id
      }
    });

    const themeOptions = [
      { label: "Dark", value: "vs-dark" },
      { label: "Light", value: "vs-light" }
    ];

    return (
      <div className="Editor flex flex-grow-1">
        {this.renderRedirect.call(this)}
        <div className="flex column flex-grow-2">
          <div className="Editor-header flex row jc-between ai-center">
            <div className="Editor-title">
              { title }
            </div>
            <div className="Editor-settings flex row jc-end">
              <div className="Editor-setting flex row ai-center">
                <span className="Editor-setting-label">Language</span>
                <Select
                  value={this.state.languageId.toString()}
                  className="select-option"
                  options={languageOptions}
                  onChange={this.setLanguage.bind(this)}
                />
              </div>

              <div className="flex row Editor-setting ai-center">
                <span className="Editor-setting-label">Theme</span>
                <Select
                  value="vs-dark"
                  className="select-option"
                  options={themeOptions}
                  onChange={this.setTheme.bind(this)}
                />
              </div>
            </div>
          </div>
          <div className="Editor-code flex flex-grow-1">
            <MonacoEditor
              language={language}
              theme={theme}
              options={options}
              editorDidMount={this.editorDidMount.bind(this)}
            />
          </div>
        </div>
        <div className="Editor-panels-cont flex column flex-grow-1">
          <div className="Editor-panel flex column flex-grow-1">
            <span className="Editor-panel-header flex row">
              <span className="Editor-title">Users</span>
            </span>

            <div className="Editor-panel-content">
              {
                users.map((user, index) => (
                  <div key={`key-${index}`} style={ {color: `${user[1].color}` } }>
                    {user[0].displayName || `Guest ${index + 1}`}
                  </div>
                ))
              }
            </div>

          </div>

          <div className="Editor-panel flex column flex-grow-3">
            <div className="Editor-panel-header flex row jc-between ai-center">
              <span className="Editor-title">Output</span>
              <div className="Editor-header-actions flex row">
                <div className="action-btn" onClick={this.runCode.bind(this)}>Run</div>
                <div className="action-btn danger" onClick={this.clearOutput.bind(this)}>Clear</div>
              </div>
            </div>
            <div className="Editor-panel-content output-panel" ref={this.outputRef}>
              {
                outputs.map((output, index) => (
                  <div className="Editor-output-result" key={`output-${index}`}>{output}</div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Editor;