import { COMPILE_API_URL } from "../constants/client";

export default {
  async getOutput(code, language) {
    const res = await fetch(COMPILE_API_URL, {
      method: "POST",
      body: JSON.stringify({ code, language }),
      headers: {
        "Content-Type": "application/json"
      }
    })

    return res.json();
  }
}