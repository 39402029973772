import React from 'react';
import firebase, { db, auth } from "../lib/Firebase";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

function getUIConfig(history) {

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    signInSuccessUrl: "/",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      // firebase.auth.GithubAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],
    autoUpgradeAnonymousUsers: true,
    callbacks: {
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        console.log("-".repeat(15));
        // Process result. This will not trigger on merge conflicts.
        // On success redirect to signInSuccessUrl.
        return true;
      },
      // signInFailure callback must be provided to handle merge conflicts which
      // occur when an existing credential is linked to an anonymous user.
      signInFailure: async (error) => {
        // For merge conflicts, the error.code will be
        // 'firebaseui/anonymous-upgrade-merge-conflict'.
        if (error.code !== 'firebaseui/anonymous-upgrade-merge-conflict') {
          return Promise.resolve();
        }
  
        const anonymousUser = auth.currentUser;
  
        // The credential the user tried to sign in with.
        const cred = error.credential;
        // If using Firebase Realtime Database. The anonymous user data has to be
        // copied to the non-anonymous user.
        // Save anonymous user data first.
        const userSnapshot = await db.ref('users/' + auth.currentUser.uid).once('value');
        const userData = userSnapshot.val();
        // This will trigger onAuthStateChanged listener which
        // could trigger a redirect to another page.
        // Ensure the upgrade flow is not interrupted by that callback
        // and that this is given enough time to complete before
        // redirection.
        const { user } = await auth.signInAndRetrieveDataWithCredential(cred);
  
        // Original Anonymous Auth instance now has the new user.
        await db.ref('users/' + user.uid).set(userData);
        // Delete anonymnous user.
        // await anonymousUser.delete();
        // FirebaseUI will reset and the UI cleared when this promise
        // resolves.
        // signInSuccessWithAuthResult will not run. Successful sign-in
        // logic has to be run explicitly.
        history.push("/");
      }
    }
  };

  return uiConfig;
}

class Login extends React.Component {

  render() {
    const { history } = this.props;
    const uiConfig = getUIConfig(history);

    return (
      <div style={{
        paddingTop: "50px"
      }}>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth}/>
      </div>
    )
  }
}

export default Login;