import { TEST_ACTION } from "../actions/types";

const initialState = {
  testItems: [],
  testItem: {
    title: "Test Title",
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TEST_ACTION:
      return {
        ...state,
        title: action.payload.title
      }
    
    // case TEST_NEW_ACTION:
    //   break;
    default:
      return state;
  }
};
