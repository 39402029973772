import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Link,
  withRouter
} from "react-router-dom";

import { db, auth } from "./lib/Firebase";
import { Provider } from "react-redux";
import store from "./store";

import Login from "./components/Login";
import Editor from "./components/Editor";

import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      theme: "dark",
      user: auth.currentUser
    };

    auth.onAuthStateChanged(async user => {
      if (!user) {
        this.setState({ user: null });
        await auth.signInAnonymously();
      }
      this.setState({ user: auth.currentUser });
    });

    const path = this.props.location.pathname;
    const codePath = path.match(/\/code\/(.+)/);

    if (!this.state.newCodeId && codePath) {

      db.ref("firepads").child(codePath[1]).once("value").then(val => {
        this.setState({
          newCodeId: val
        });
      });
    } else {
      this.state.newCodeId = db.ref("firepads").push();
    }

  }

  render() {
    const { theme, user } = this.state;
    
    return (
      <Provider store={store}>

        <Router>
          <div className={`App flex column flex-grow-1 ${theme}`}>
            <Route render={({ history}) => (
              <div className="App-header flex ai-center jc-between">
                <div className="App-product-name">
                  <Link to="/">Collab Code</Link>
                </div>
                <div className="App-header-actions">
                  {
                    (user && !user.isAnonymous) ?
                      (<div>
                        <span>{user.displayName || "Me"}</span>
                        <span className="App-action-btn" onClick={async () => {
                          await auth.signOut();
                          this.setState({ newCodeId: db.ref("firepads").push() });
                          history.push("/");
                        }}>Logout</span>
                      </div>) :
                      <div>
                        <Link to="/signin" className="App-action-btn">Login</Link>
                        <Link to="/signin" className="App-action-btn">Signup</Link>
                      </div>
                  }
                </div>
              </div>
            )} />
            <Route exact path="/" render={() => (
              <Redirect to={`/code/${this.state.newCodeId.key}`} />
            )} />
            <Route exact path="/code" render={() => (
              <Redirect to="/" />
            )} />
            <Route exact path="/code/:codeId" render={(props) => (
              user ?
                <Editor {...props} /> :
                <div className="loader"></div>
            )} />
            <Route exact path="/signin" component={Login} />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default withRouter(App);
