export const COMPILE_API_URL = "https://apicollabcode.ykhatri.com/compile";
export const LANGUAGES = [
  { id: 0, name: "Python", langCode: "python" },
  { id: 1, name: "Ruby", langCode: "ruby" },
  { id: 2, name: "Clojure", langCode: "clojure" },
  { id: 3, name: "PHP", langCode: "php" },
  { id: 4, name: "Javascript", langCode: "javascript" },
  // { id: 5, name: "Scala", langCode: "scala" },
  { id: 6, name: "Go", langCode: "go" },
  { id: 7, name: "C/C++", langCode: "cpp" },
  { id: 8, name: "Java", langCode: "java" },
  // { id: 9, name: "VB.NET", langCode: "vb.net" },
  { id: 10, name: "C#", langCode: "csharp" },
  { id: 11, name: "Bash", langCode: "powershell" },
  { id: 12, name: "Objective-C", langCode: "objective-c" },
  { id: 13, name: "MySQL", langCode: "mysql" },
  { id: 14, name: "Perl", langCode: "perl6" },
  { id: 15, name: "Rust", langCode: "rust" },
]