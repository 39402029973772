import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyDy1s93kZMpyMYi9DIC5LW3jxgf016lW38",
  authDomain: "collabcode42.firebaseapp.com",
  databaseURL: "https://collabcode42.firebaseio.com",
  projectId: "collabcode42",
  storageBucket: "collabcode42.appspot.com",
  messagingSenderId: "740778699171"
};

firebase.initializeApp(config);

export const db = firebase.database();
export const auth = firebase.auth();

export default firebase;